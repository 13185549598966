import React, { useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import TextInput from '../components/fields/text-input';
import loadFunctions from '../components/firebase-functions';

function SubscriptionForm() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });

    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        try {
            const functions = await loadFunctions();
            await functions.httpsCallable('subscribeMailChimpUser')({
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
            });
            setSubmitted(true);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout>
            <SEO title="Newsletter | J Clarke Fitness" />
            <div className={`subscription-form ${submitted ? "submitted" : ""}`}>
                <h2 className="title">
                    {!submitted ? 'FAMOUS WEEKLY EMAILS' : 'Thank you for subscribing!'}
                </h2>
                {!submitted && (
                    <>
                        <p className="description">
                            Subscribe for exclusive content regarding training, nutrition, my
                            personal life and special offers… basically the stuff that is too good
                            for social media 😉
                        </p>
                        <form onSubmit={handleSubmit} className="form">
                            <div className="name_inputs">
                                <TextInput
                                    label="First name"
                                    value={formData.firstName}
                                    required={true}
                                    onChange={value => handleInputChange('firstName', value)}
                                />
                                <TextInput
                                    label="Last name"
                                    value={formData.lastName}
                                    required={true}
                                    onChange={value => handleInputChange('lastName', value)}
                                />
                            </div>
                            <TextInput
                                label="Email"
                                value={formData.email}
                                required={true}
                                onChange={value => handleInputChange('email', value)}
                                type="email"
                            />

                            <button type="submit" className="subscribe-button" disabled={loading}>
                                {loading ? 'Subscribing...' : 'Subscribe'}
                            </button>
                        </form>
                    </>
                )}
            </div>
            <style>
                {`
                  .subscription-form {
                    max-width: 500px;
                    margin: 0 auto;
                    padding: 20px;
                    border-radius: 4px;
                  }

                  .subscription-form.submitted {
                    min-height: calc(100vh - 424px);
                  }
                  
                  .title {
                    text-align: center;
                  }
                  
                  .description {
                    margin-bottom: 20px;
                    text-align: center;
                  }
                  
                  .form {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                  }
                  
                  .name_inputs {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                  }
                  
                  .subscribe-button {
                    padding: 10px 20px;
                    background-color: #f44034;
                    color: white;
                    border: none;
                    border-radius: 3px;
                    cursor: pointer;
                    font-size: 16px;
                    align-self: flex-end;
                  }
                `}
            </style>
        </Layout>
    );
}

export default SubscriptionForm;
